import axios from "@/plugins/axios";
import axiosCategory from "@/plugins/axiosCategory";
import qs from "qs";

export const getAllCategories = async () => {
  const query = qs.stringify(
    {
      populate: {
        localizations: "*",
        image: "*",
        products: {
          populate: {
            localizations: "*",
          },
        },
      },

      sort: ["order:asc"],
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axiosCategory.get(`/categories?${query}`);
  return response?.data?.data;
};

export const getCategoriesByIds = async (ids) => {
  const query = qs.stringify(
    {
      populate: {
        products: {
          populate: "*",
        },
      },
      filters: {
        id: {
          $in: ids,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/categories?${query}`);
  return response?.data?.data;
};

export const getCategorizedProducts = async (items, cityId) => {
  const query = qs.stringify(
    {
      populate: {
        localizations: "*",
        image: "*",
      },
      sort: ["order:asc"],
      items: items,
      filters: {
        cities: {
          id: { $in: cityId },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/categorized-products?${query}`);
  return response?.data?.data;
};
