import axios from "axios";
import i18n from "@/plugins/i18n";

const http = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  timeout: 100000,
});
http.defaults.params = {};

http.interceptors.response.use(
  function (response) {
    let lang = i18n.locale;
    if (lang === "uz") {
      if (Array.isArray(response?.data?.data)) {
        response?.data?.data.forEach((e) => {
          let localeAttributes =
            e?.attributes?.localizations?.data[0]?.attributes;
          if (localeAttributes) {
            for (const [key, val] of Object.entries(localeAttributes)) {
              e.attributes[key] = val;
            }
          }
          if (e.attributes.products.data.length) {
            e.attributes.products.data.forEach((p) => {
              let localeAttributes =
                p.attributes?.localizations?.data[0]?.attributes;
              if (localeAttributes) {
                for (const [key, val] of Object.entries(localeAttributes)) {
                  p.attributes[key] = val;
                }
              }
            });
          }
        });
      }
      return response;
    }

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default http;
